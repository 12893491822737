body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  max-width: 100vw;
  overflow: hidden;
}

.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

#cookieBanner {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.progress {
  height: 120px
}

.progress > svg {
  height: 100%;
  display: block;
}

.progressbar-text {
  font-size: x-large;
  font-weight: 900;
}
